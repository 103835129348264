import styled from "styled-components";
import { Column } from "../../../components/common";
import Colors from "../../../theme/colors";
import Fonts from "../../../theme/fonts";

export const PlanDetailsContainer = styled(Column)`
  background: #fff1e7;
  margin-top: -2rem;
  margin-bottom: 1rem;
  padding: 1rem;
  padding-top: 2.5rem;
  border-radius: 1rem;

  .field-title {
    color: #fe7b00;
  }

  .phone-field {
    min-height: 3rem;
    box-shadow: 0 0 0.5rem #eeeeee;
    border-radius: 8px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex: 1;
    font-size: 18px !important;
    font-weight: 600;
  }

  .react-tel-input .form-control {
    border: none !important;
    font-weight: 600;
    padding-left: 64px !important;
    font-family: "${Fonts.primary}";
  }
  .react-tel-input .flag-dropdown {
    border: none !important;
    border-right: 1px solid #bdbdbd !important;
    background-color: transparent !important;
  }
  .react-tel-input .selected-flag {
    width: 58px !important;
    padding-left: 10px !important;
  }
  .react-tel-input .selected-flag .arrow {
    border-left: 6px solid transparent !important;
    border-right: 6px solid transparent !important;
    border-top: 7px solid #fe7b00 !important;
  }
  span {
    font-family: "${Fonts.primary}";
  }
`;

export const CouponContainer = styled("div")<{ disabled: boolean }>`
  border-radius: 0.5rem;
  border: 1px solid ${(props) => (props.disabled ? "#C1C1C1" : Colors.orange)};
  color: ${(props) => (props.disabled ? "#C1C1C1" : "black")};
  padding: 0.5rem;
  margin: 0.3rem 0;
`;
