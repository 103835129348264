export interface PlanConfig {
  type: PlanType;
  price: number;
  featurePrice: number;
  superPrice: number;
  monthlyPrice?: number;
  discount: number;
}

export const allPlans: PlanConfig[] = [
  {
    type: "lifetime",
    price: 3999,
    discount: 400,
    featurePrice: 2700,
    superPrice: 1299,
  },
  {
    type: "annual",
    price: 2999,
    monthlyPrice: 250,
    discount: 200,
    featurePrice: 2000,
    superPrice: 999,
  },
  {
    type: "basic-annual",
    price: 1999,
    monthlyPrice: 167,
    featurePrice: 1999,
    superPrice: 0,
    discount: 0,
  },
];

export type PlanType = "lifetime" | "annual" | "basic-annual";
