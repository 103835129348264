import axios from "axios";
import { PlanConfig } from "../../Plans/plansConfig";

export const payment = {
  generateOrderId: async (plan: string): Promise<{ id: string; amount: number }> => {
    const options = {
      plan: plan,
    };
    const response = await axios.post("/api/payment", options);
    return response.data;
  },
  generateSubscriptionId: async (plan: string, discountApplied: boolean): Promise<string> => {
    const options = {
      plan,
      discountApplied,
    };
    const response = await axios.post("/api/subscription", options);
    return response.data.id;
  },
  startPayment: (
    orderId: string | null,
    amount: number | null,
    subscriptionId: string | null,
    method: string | null,
    phoneNumber: string,
    onFailure: (error: any) => void,
    onClose: () => void
  ): Promise<string> => {
    return new Promise((resolve, reject) => {
      const commonOptions = {
        key: process.env.REACT_APP_RAZORPAY_API_KEY,
        currency: "INR",
        name: "Little Singham",
        description: "Registration",
        handler: function (response: any) {
          console.log(response);
          resolve(response.razorpay_payment_id);
        },
        prefill: {
          contact: phoneNumber,
          email: "payments@creativegalileo.com",
          method: method,
        },
        theme: {
          color: "#FFB800",
        },
        modal: {
          ondismiss: onClose,
        },
      };
      const options = orderId
        ? { ...commonOptions, order_id: orderId, amount: amount }
        : { ...commonOptions, subscription_id: subscriptionId };
      // @ts-ignore
      const razorPay = new window.Razorpay(options);
      razorPay.on("payment.failed", function (response: any) {
        onFailure(response.error);
      });
      razorPay.open();
    });
  },
};

export const startPaymentFlow = async (
  subscription: PlanConfig,
  phoneNumber: string,
  discountApplied: boolean,
  onSuccess: (paymentId: string) => void,
  onClose: () => void,
  setLoader: (loading: boolean) => void,
  setError: (error: string) => void
) => {
  const isFreeTrialEnabled = process.env.REACT_APP_FREE_TRIAL_ENABLED === "true";
  try {
    setLoader(true);
    const { id: orderId, amount } = isFreeTrialEnabled
      ? { id: null, amount: null }
      : await payment.generateOrderId(subscription.type);
    const subscriptionId = isFreeTrialEnabled
      ? await payment.generateSubscriptionId(subscription.type, discountApplied)
      : null;
    const planAmount = subscription.price;
    axios.post("https://sheet.best/api/sheets/02f90cf4-04d4-45e6-ada5-d2a6aaa8a982", {
      "Phone Number": "+" + phoneNumber,
      Pricing: planAmount.toString(),
      Date: new Date().toString(),
    });
    setLoader(false);
    try {
      const paymentId = await payment.startPayment(
        orderId,
        amount,
        subscriptionId,
        null,
        phoneNumber,
        (error) => {
          console.log(error);
          const paymentId = error.metadata?.payment_id;
          setError(`Payment failed.<br/><b>Transaction id:</b> ${paymentId || ""}`);
        },
        onClose
      );
      setError("");
      setLoader(true);
      try {
        const data = {
          "Phone Number": "+" + phoneNumber,
          Pricing: planAmount.toString(),
          "Payment Type": "",
          Date: new Date().toString(),
          "Subscription ID": subscriptionId,
          "Order ID": orderId,
          "Payment ID": paymentId,
        };
        await axios.post("https://sheet.best/api/sheets/02f90cf4-04d4-45e6-ada5-d2a6aaa8a982", data);
      } catch (e: any) {
        console.log(e);
      }
      setLoader(false);
      onSuccess(paymentId);
    } catch (e: any) {
      console.log(e);
      setError("Something went wrong");
    }
  } catch (e: any) {
    console.log(e);
    setError(e.message);
    setLoader(false);
  }
};
