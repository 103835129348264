import styled from "styled-components";
import Colors from "../../../theme/colors";

export const PlanCardContainer = styled("div")<{ selected: boolean }>`
  position: relative;
  margin: 0.5rem 0;
  background: white;
  box-shadow: 0 0 0.5rem #dddddd;
  border: ${(props) => (props.selected ? "0.1rem solid #FFB800" : "none")};
  border-radius: 0.6rem;
  display: flex;
  flex-direction: row;
  padding: 1rem;
  cursor: pointer;
  .main-price {
    font-weight: 500;
    font-size: 1.4rem;
    margin: 0.2rem 0;
  }
  .original-price {
    text-decoration: line-through;
    font-size: 0.8rem;
  }
  .monthly-price {
    font-size: 0.8rem;
    color: #6f6571;
  }
  .dot {
    height: 1rem;
    margin-right: 0.5rem;
    font-size: 2rem;
  }
`;

export const Separator = styled("div")<{ selected: boolean }>`
  border-left: 1px solid #d9d9d9;
`;

export const Caption = styled("span")`
  position: absolute;
  right: 0;
  top: 0;
  background: ${Colors.purple};
  border-radius: 0.5rem;
  border-bottom-right-radius: 0;
  font-size: 0.8rem;
  padding: 0 0.5rem;
  color: white;
`;
