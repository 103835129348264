/* eslint-disable jsx-a11y/alt-text */
import { Column, InvisibleButton, Row, Rupee } from "../../../components/common";
import Colors from "../../../theme/colors";
import images from "../assets/images";
import { PlanConfig } from "../plansConfig";
import { Caption, PlanCardContainer, Separator } from "./PlanCardStyled";
import { PlanDetails } from "./PlanDetails";

interface PlanCardProps {
  className: string;
  config: PlanConfig;
  selected: boolean;
  phoneNumber: string;
  onPhoneNumberChanged: (phoneNumber: string) => void;
  onClick: () => void;
  termsAccepted: boolean;
}

export const PlanCard: React.FC<PlanCardProps> = ({
  className,
  config,
  selected,
  phoneNumber,
  onPhoneNumberChanged,
  onClick,
  termsAccepted,
}) => {
  const { type, price, monthlyPrice } = config;
  return (
    <Column className={className}>
      <PlanCardContainer
        selected={selected}
        onClick={() => {
          document.getElementById(className)?.click();
          onClick();
        }}
      >
        <Column style={{ width: "60%", fontSize: "0.8rem", paddingRight: "0.5rem" }}>
          <Row style={{ justifyContent: "flex-start", lineHeight: "1rem" }}>
            <div style={{ width: "1.5rem", marginRight: "0.5rem" }}>
              <img src={images.Phone} style={{ width: "1rem" }} />
            </div>
            <div style={{ flex: 1 }}>
              <span>
                <b>{type === "lifetime" ? "Lifetime" : "1 year"} access</b> to premium app features
              </span>
            </div>
          </Row>
          {type !== "basic-annual" && (
            <Row style={{ justifyContent: "flex-start", lineHeight: "1rem", marginTop: "1rem" }}>
              <div style={{ width: "1.5rem", marginRight: "0.5rem" }}>
                <img src={type === "lifetime" ? images.ThreeYears : images.OneYear} style={{ width: "1.5rem" }} />
              </div>
              <div style={{ flex: 1 }}>
                <span>{type === "lifetime" ? "3 years" : "1 year"} supply of Super Activity Box</span>
              </div>
            </Row>
          )}
        </Column>
        <Separator selected={selected} />
        <Column
          style={{ paddingLeft: "0.5rem", alignItems: "flex-end", flex: "1", lineHeight: "1.3rem", fontSize: "0.9rem" }}
        >
          <span style={{ fontSize: "1rem", fontWeight: 500, color: type === "lifetime" ? Colors.purple : "#3EAEFF" }}>
            {type === "lifetime" ? "Lifetime" : type === "annual" ? "Annual" : "Basic Annual"}
          </span>
          <span className="main-price">
            <Rupee /> {price.toLocaleString()}
          </span>
          {type !== "lifetime" && monthlyPrice && (
            <>
              <span className="monthly-price">
                <Rupee /> {monthlyPrice}/monthly
              </span>
            </>
          )}
        </Column>
        {type === "lifetime" && <Caption>Parent's Choice</Caption>}
      </PlanCardContainer>
      {selected && (
        <PlanDetails
          config={config}
          phoneNumber={phoneNumber}
          onPhoneNumberChanged={onPhoneNumberChanged}
          termsAccepted={termsAccepted}
        />
      )}
      <InvisibleButton id={className} />
    </Column>
  );
};
