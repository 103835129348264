/* eslint-disable jsx-a11y/alt-text */
import { useState } from "react";
import { Oval } from "react-loader-spinner";
import ReactModal from "react-modal";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useNavigate } from "react-router-dom";
import { PrimaryButton } from "../../../components/button/PrimaryButton";
import { Caret, Column, InvisibleButton, Line, Row, Rupee } from "../../../components/common";
import { modalStyles } from "../../../components/modal/Modal";
import Colors from "../../../theme/colors";
import CommonImages from "../../Home/assets/images";
import { startPaymentFlow } from "../../Payment/pay/payment";
import { allPlans, PlanConfig } from "../plansConfig";
import { Coupon } from "./Coupon";
import { PlanDetailsContainer } from "./PlanDetails.styled";

export const PlanDetails: React.FC<{
  config: PlanConfig;
  phoneNumber: string;
  onPhoneNumberChanged: (phoneNumber: string) => void;
  termsAccepted: boolean;
}> = ({ config, phoneNumber, onPhoneNumberChanged, termsAccepted }) => {
  const [couponsExpanded, setCouponsExpanded] = useState(false),
    [selectedCoupon, setSelectedCoupon] = useState<number | null>(null),
    [isLoading, setIsLoading] = useState(false),
    [error, setError] = useState(""),
    navigate = useNavigate(),
    type = config.type;

  const lifetimePlanConfig = allPlans.find((p) => p.type === "lifetime"),
    annualPlanConfig = allPlans.find((p) => p.type === "annual"),
    featurePrice = config.featurePrice,
    superPrice = config.superPrice;

  const onPaymentClicked = () => {
      if (phoneNumber.length !== 12) {
        setError("Please enter valid phone number");
        return;
      }
      document.getElementById("make_payment")?.click();
      startPaymentFlow(
        config,
        phoneNumber,
        selectedCoupon != null,
        (paymentId) => {
          document.getElementById("payment_success")?.click();
          navigate("/thank-you", { state: { mobile: phoneNumber, plan: type, paymentId: paymentId } });
        },
        () => {
          document.getElementById("payment_dropoff")?.click();
        },
        setIsLoading,
        (message) => {
          if (message.length === 0) return;
          setError(message);
          document.getElementById("payment_failure")?.click();
        }
      );
    },
    onPlanClicked = (amount: number) => {
      if (selectedCoupon === amount) {
        setSelectedCoupon(null);
      } else {
        setSelectedCoupon(amount);
      }
    };

  return (
    <PlanDetailsContainer>
      <span style={{ marginBottom: "0.5rem" }}>
        <b>Mobile Number:</b>
      </span>
      <PhoneInput
        onlyCountries={["in"]}
        containerClass="phone-field"
        inputClass="phone-field"
        placeholder="Phone Number"
        country="in"
        countryCodeEditable={false}
        value={phoneNumber}
        onChange={onPhoneNumberChanged}
      />
      {type !== "basic-annual" && (
        <Row
          style={{ marginTop: "0.5rem", alignItems: "center", cursor: "pointer", justifyContent: "flex-start" }}
          onClick={() => setCouponsExpanded(!couponsExpanded)}
        >
          <span style={{ color: Colors.orange, fontWeight: 500 }}>2 Coupons Available</span>
          <Caret expanded={couponsExpanded} />
        </Row>
      )}
      {couponsExpanded && (
        <Column>
          {type === "lifetime" ? (
            <>
              <Coupon
                disabled={false}
                price={lifetimePlanConfig?.price || 0}
                discount={lifetimePlanConfig?.discount || 0}
                selected={selectedCoupon === lifetimePlanConfig?.discount}
                onClick={lifetimePlanConfig && (() => onPlanClicked(lifetimePlanConfig?.discount))}
              />
              <Coupon
                disabled={true}
                price={annualPlanConfig?.price || 0}
                discount={annualPlanConfig?.discount || 0}
                selected={false}
              />
            </>
          ) : (
            <>
              <Coupon
                disabled={false}
                price={annualPlanConfig?.price || 0}
                discount={annualPlanConfig?.discount || 0}
                selected={selectedCoupon === annualPlanConfig?.discount}
                onClick={() => onPlanClicked(annualPlanConfig?.discount || 0)}
              />
              <Coupon
                disabled={true}
                price={lifetimePlanConfig?.price || 0}
                discount={lifetimePlanConfig?.discount || 0}
                selected={false}
              />
            </>
          )}
        </Column>
      )}
      <Column style={{ marginTop: "1rem" }}>
        <Row style={{ fontWeight: 500 }}>
          <span style={{ flex: 1 }}>Premium App Features</span>
          <span>
            <Rupee /> {featurePrice.toLocaleString()}
          </span>
        </Row>
        {type !== "basic-annual" && (
          <Row style={{ fontSize: "0.8rem", color: "#6F6571" }}>
            <span style={{ flex: 1 }}>Super Activity Box (non-refundable)</span>
            <span>
              <Rupee /> {superPrice.toLocaleString()}
            </span>
          </Row>
        )}
        {selectedCoupon && (
          <Row style={{ fontSize: "0.8rem", color: "#6F6571" }}>
            <span style={{ flex: 1 }}>Coupon Applied</span>
            <span>
              - <Rupee /> {` ${selectedCoupon.toLocaleString()}`}
            </span>
          </Row>
        )}
        <Line style={{ width: "100%" }} />
        <Row style={{ fontWeight: 700 }}>
          <span style={{ flex: 1 }}>Grand Total</span>
          <span>
            <Rupee /> {(featurePrice + superPrice - (selectedCoupon || 0)).toLocaleString()}
          </span>
        </Row>
      </Column>
      <PrimaryButton
        style={{ width: "80%", alignSelf: "center", marginTop: "1.5rem" }}
        onClick={onPaymentClicked}
        disabled={!termsAccepted}
      >
        Make Payment
      </PrimaryButton>

      <ReactModal isOpen={isLoading} style={modalStyles}>
        <Row style={{ justifyContent: "flex-start", alignItems: "center" }}>
          <Oval height={30} width={30} color={Colors.orange} strokeWidth={5} />{" "}
          <span style={{ marginLeft: "1rem" }}>Please wait...</span>
        </Row>
      </ReactModal>
      <ReactModal isOpen={error.length > 0} style={modalStyles} onRequestClose={() => setError("")}>
        <div dangerouslySetInnerHTML={{ __html: error }}></div>
        <img
          src={CommonImages.Close}
          style={{ width: "1rem", position: "absolute", right: "0.5rem", top: "0.5rem" }}
          alt="close"
          onClick={() => setError("")}
        />
      </ReactModal>
      <InvisibleButton id="make_payment" />
      <InvisibleButton id="payment_success" />
      <InvisibleButton id="payment_failure" />
      <InvisibleButton id="payment_dropoff" />
    </PlanDetailsContainer>
  );
};
